/** @format */
div:hover a {
  text-decoration: none;
}

li:hover a {
  text-decoration: none;
}

a {
  text-decoration: none;
}

a,
img {
  display: block;
}

/*=====================================================*/
/* step */
.MuiStepper-root {
  background-color: transparent !important;
}

/*=====================================================*/
/* 아이소 글라스 */
.aiso_glass_in_bg {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border: 3px solid rgba(255, 255, 255, 0.5) !important;
}

/*=====================================================*/
/* 앱 디테일 화면 */
.aiso_detail_wrap {
  /* backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important; */
  background-color: rgba(255, 255, 255, 0.6) !important;
  border: 5px solid rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.3) !important;
  border-radius: 35px;
}
.aiso_detail_header {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-bottom: 3px solid rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 30px 30px 0 0;
}
.aiso_detail_footer {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-bottom: 3px solid rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 0 0 30px 30px;
}
/* 앱 디테일 - input output 영역 */
.inoutput_header {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-bottom: 3px solid rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 6px 6px 0 0;
}
/* 앱 업로드 - 파라미터, 이미지파일 랩 높이 */
.min_h_250 {
  min-height: 250px;
}

/*=====================================================*/
.border- {
  border-width: 1px;
}

.font-bold {
  font-weight: bold !important;
}

.text-logo {
  font-family: "REZ", Arial, Helvetica, sans-serif !important;
}

.logo-a-color {
  color: #80ceff;
}

.logo-iso-color {
  color: #5395ff;
}

.MuiRadio-root {
  color: #aaa !important;
}

.MuiFormControlLabel-root span.MuiTypography-body1 {
  margin-top: 4px;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #5395ff !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-ss {
  font-size: 10px;
}

.text-sss {
  font-size: 8px;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 15px !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.icon-lg {
  font-size: 20px !important;
}

/*=====================================================*/
/* 전체 화면 너비 리셋 */
iframe html body .content > .container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/*=====================================================*/
/* 전체 화면 너비 리셋 */

.content-reset {
  margin: -1.68rem -0.5rem;
  height: calc(100% + 1.68rem + 1.68rem);
}

.content-reset-white {
  margin: -1.68rem -0.5rem;
  height: 100%;
  padding: 1.68rem 1rem;
  /* background-color: #fff; */
}

.tab-content-reset {
  margin: -2rem -1rem;
  padding: 1.68rem 0.5rem;
}

.admin-max-w {
  max-width: 75rem;
}

/*=====================================================*/

.center-dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #333;
  text-align: center;
  margin: 0 6px;
}

.max-w-8xl {
  max-width: 85rem;
}

/* Header User Icon Menu */
.sidebar .nav .nav-item.active > .nav-link .side-menu,
.sidebar .nav .nav-item.active > .nav-link .menu-arrow {
  color: #5395ff;
  font-weight: bold;
}

.sidebar .nav .nav-item.active .nav-link svg {
  fill: #5395ff;
}

/*=====================================================*/
/* only-one content */
.h-lg,
.max-h-lg {
  height: 34rem;
}

.model-img {
  background-image: url(/images/market/no-img.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.mo-sm-hidden {
  display: none;
}

.text-hidden {
  display: inline-block;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

/*=====================================================*/
/* Box-Shadow */
.aiso-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

.aiso-shadow:hover {
  box-shadow: 0px 0px 8px 0px rgba(4, 17, 29, 0.25);
  transform: translateY(-2px);
  transition: all 0.2s ease 0s;
}

/*=====================================================*/
/* Flex 스타일 */
.center {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.center2 {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.row-center {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.row-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.row-around {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
}

/*=====================================================*/
/*=== 설명 TEXT STYLE ===*/
.wrap-box {
  margin-top: 25px;
}

.wrap-box:last-of-type {
  margin-bottom: 25px;
}

.sub-title {
  font-size: 15px;
  line-height: 1.5;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.text-content {
  font-size: 13px;
  line-height: 1.3;
  color: #333;
}

/*=====================================================*/
/*=== eee-outline BUTTON STYLE ===*/
.eee-outline-btn {
  border: 1px solid #eee;
  background-color: #fff;
  color: #888;
  padding: 0.5rem 1rem;
  font-size: 13px;
  height: 100%;
}

.eee-outline-btn > i {
  font-size: 14px;
}

.eee-outline-btn:hover {
  border: none;
  background-color: #5395ff;
  color: #fff;
}

.eee-outline-btn:hover > i {
  font-weight: bold;
}

/*=====================================================*/
/*=== 최상단 타이틀 STYLE ===*/
.top-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  color: #333;
}

/*=====================================================*/
/*=== 타이틀 하단 메뉴 STYLE ===*/
.title-under-menu-group > li > a {
  width: max-content;
}

.title-under-menu-group > li {
  margin-top: 10px;
}

.title-under-menu-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.title-under-menu {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: #333 !important;
  margin: 0.2rem;
  padding: 0.4rem rem !important;
  cursor: pointer;
}

.title-under-menu:hover {
  font-weight: bold;
  color: #fff !important;
  background-color: #5395ff;
  text-decoration: none;
}

.title-under-menu.active {
  font-weight: bold;
  color: #fff !important;
  background-color: #5395ff;
}

/*=====================================================*/
/*=== 카테고리 심플 메뉴 STYLE ===*/
.category-menu-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  height: 35px;
}

.category-menu-group > li > a {
  width: max-content;
}

ul {
  padding-left: 0 !important;
}

li.active a {
  text-decoration: none !important;
}

.category-simple-menu {
  color: #555 !important;
  padding: 0 !important;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 0.3rem 0.7rem !important;
  min-width: max-content;
  font-family: "GmarketSansMedium";
  border: 1px solid #fff;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.2);
}

.category-simple-menu:hover {
  color: #333 !important;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.4);
}

.category-simple-menu.active {
  color: #000 !important;
  font-weight: bold;
  border: 2px solid #fff;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.category-simple-menu-line {
  color: #888;
}

.no-content:after {
  content: none !important;
}

/*=====================================================*/
/*=== Dropdown Toggle STYLE ===*/
.no-arrow-dropdown:after {
  content: none !important;
}

.no-style-dropdown {
  border: none !important;
  background-color: #fff !important;
  color: #555 !important;
  font-weight: 700 !important;
  padding: 0.5rem 1rem !important;
}

.base-style-dropdown {
  border: 1px solid #eee !important;
  background-color: #f9fafb !important;
  color: #333 !important;
  height: 3.175rem !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #f9fafb !important;
  color: #5395ff !important;
}

/*=====================================================*/
/*=== Dropdown Toggle STYLE ===*/
.select-style > option {
  padding: 10px;
  background-color: #fff;
}

/*=====================================================*/
/*===== File-BTN =====*/
.custom-file-input {
  position: absolute !important;
  height: 100% !important;
  top: 0;
  left: 0;
}

.label-upload {
  border: 1px solid #eee;
  background-color: #f9fafb;
  color: #333;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
}

/*=====================================================*/
/*=== Site-Circle-Icon ===*/
.site-icon-group > .site-circle-icon {
  margin: 0 0.2rem;
}

.site-circle-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-circle-icon > i {
  color: #fff;
  font-size: 20px !important;
}

.dropdown-menu {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 44px, 0px);
}

/* Loding */
.circle-loader {
  width: 50px !important;
  height: 50px !important;
}

.circle-loader:before,
.circle-loader:after {
  border-width: 7px !important;
}

.circle-loader:after {
  border-color: #fff !important;
}

.circle-loader:before {
  border-top-color: #333 !important;
}

/* lg-Loader */
.circle-lg-loader > .circle-loader {
  width: 30px !important;
  height: 30px !important;
}

.circle-lg-loader > .circle-loader:before,
.circle-lg-loader > .circle-loader:after {
  border-width: 5px !important;
}

.circle-lg-loader > .circle-loader:after {
  border-color: #fff !important;
}

.circle-lg-loader > .circle-loader:before {
  border-top-color: #333 !important;
}

/*=====================================================*/
/*=== 결제 ===*/

@media (min-width: 1536px) {
  /*=============== 2xl ===============*/
  .col-2xl-2 {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }

  .col-2xl-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-2xl-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .flex-2xl-between-row {
    justify-content: space-between !important;
    flex-direction: row !important;
  }

  .justify-content-2xl-center {
    justify-content: center !important;
  }

  .justify-content-2xl-end {
    justify-content: flex-end !important;
  }

  .align-content-2xl-center {
    align-items: center !important;
  }

  .align-content-2xl-end {
    align-items: flex-end !important;
  }
}

@media (min-width: 1070px) {
  /*=== BUTTON STYLE ===*/
  .eee-outline-btn {
    padding: 0.5rem 1rem;
    font-size: 14px;
  }

  .eee-outline-btn > i {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  /**=============== xl *===============*/
}

@media (min-width: 1024px) {
  /**=============== lg *===============*/
}

@media (min-width: 768px) {
  /**=============== md *===============*/
}

@media (min-width: 639px) {
  /**=============== sm *===============*/
  .content-reset {
    margin: -1.68rem -2.5rem;
  }

  .content-reset-white {
    margin: -1.68rem -2.5rem;
    padding: 1.68rem 2rem;
  }

  /*=== TEXT STYLE ===*/
  .wrap-box {
    margin-top: 40px;
  }

  .wrap-box:last-of-type {
    margin-bottom: 40px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .sub-title {
    font-size: 18px;
  }

  .text-content {
    font-size: 14px;
  }

  .mo-sm-hidden {
    display: flex;
  }
}

@media (min-width: 480px) {
  /*=== 카테고리 심플 메뉴 STYLE ===*/
  .category-simple-menu {
    font-size: 14px;
    padding: 0.3rem 1.2rem !important;
  }

  .top-title {
    font-size: 24px;
  }

  .title-under-menu-group {
    justify-content: center;
  }

  .title-under-menu {
    padding: 0.65rem 1.5rem;
    margin: 0 0.5rem;
    font-size: 15px;
  }

  /*=== progress table STYLE ===*/
  .report-table > tbody > tr > td {
    padding: 2rem 0;
  }
}

/*========== Circle Number List ==========*/

ul.circle-number-list {
  counter-reset: li;
  list-style-type: none;
  font-size: 15px;
  line-height: 18px;
  padding-left: 10px;
  color: #555;
}

ul.circle-number-list > li {
  position: relative;
  padding: 5px 0 5px 28px;
}

ul.circle-number-list > li::before {
  content: counter(li);
  counter-increment: li;
  color: black;
  font-size: 11px;
  text-align: center;
  position: absolute;
  left: 5px;
  top: 7.2px;
  font-family: "GmarketSansBold";
}

ul.circle-number-list > li:first-child::before {
  left: 6px;
  top: 7.5px;
}

ul.circle-number-list > li::after {
  content: "";
  height: 16px;
  width: 16px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 8px;
}

/* 숫자 리스트 */
.list-decimal {
  margin-left: 18px;
  color: #555;
  margin-bottom: 10px;
}

.list-decimal > li {
  margin-top: 15px;
}

.list-decimal > li > p {
  font-size: 15px;
  line-height: 1.75em;
  margin-left: 10px;
}

.list-decimal > li > p:first-child {
  margin-bottom: 8px;
}

::marker {
  font-weight: bold;
  font-size: 15px;
}

/* 기호 리스트 */
ul.arrow-list {
  list-style-type: none;
  font-size: 15px;
  line-height: 18px;
  padding-left: 10px;
  color: #555;
}

ul.arrow-list > li {
  position: relative;
  padding: 5px 0 5px 24px;
}

ul.arrow-list > li > p {
  font-size: 15px;
  line-height: 1.75em;
}

ul.arrow-list > li::before {
  content: "▶";
  color: black;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 5px;
}

/*========== Media Query Max-Width ==========*/

.mo-block-hidden {
  display: block;
}

.mo-flex-hidden {
  display: flex;
}

.mo-hidden {
  display: flex;
}

.mo-show {
  display: none;
}

.pc-hidden {
  display: none;
}

.tab-show {
  display: none;
}

.tab-hidden {
  display: block;
}

@media (max-width: 2200px) {
  .flex-sm-row-xl-col-2xl-row {
    flex-direction: row;
  }
}

@media (max-width: 1705px) {
}

@media (max-width: 1500px) {
  .flex-sm-row-xl-col-2xl-row {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .flex-sm-row-xl-col-2xl-row {
    flex-direction: row;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 767px) {
  .pc-hidden {
    display: block;
  }

  .tab-hidden {
    display: none;
  }

  .tab-show {
    display: block;
  }
}

@media (max-width: 480px) {
  .mo-hidden {
    display: none;
  }

  .mo-show {
    display: block;
  }

  .mo-block-hidden {
    display: none;
  }

  .mo-flex-hidden {
    display: none;
  }

  .tab-show {
    display: none;
  }

  .card-body {
    padding: 1rem !important;
  }

  .after-line::before {
    display: none;
  }

  .flex-sm-row-xl-col-2xl-row {
    flex-direction: column;
  }
}

/* beta react-pdf library css */
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 90% !important;
  height: 90% !important;
  display: table-cell;
  vertical-align: middle;
}

.react-pdf__Page {
  /* position: static !important; */
  width: auto !important;
  height: auto !important;
}

.react-pdf__Page__textContent {
  display: none !important;
}
.react-pdf__Page__textContent span {
  display: none !important;
}
