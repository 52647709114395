/** @format */
/*=========== 데시보드 미포함 ==========*/

.scaleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.scaleBox:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: scale(1.1);
  cursor: pointer;
  transition: ease-in-out 0.3s;
}
.scaleBox:hover > .scaleBox-child {
  display: block;
}
.textd {
  color: blue;
}

/*=========== 마켓 데이터 분석 - 데이터 분석 리스트  ==========*/
.glass-box {
  backdrop-filter: blur(5px) saturate(200%);
  -webkit-backdrop-filter: blur(5px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.49);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 7px;
}
.glass-box > div > svg {
  font-size: 3rem;
  color: #5395ff;
}
.glass-box:hover > div > svg {
  color: #0062ff !important;
  transition: ease-in-out 0.2s;
}
.data-add {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.data-success {
  background: rgb(0, 71, 255);
  background: linear-gradient(156deg, rgba(0, 71, 255, 1) 0%, rgba(75, 128, 255, 1) 52%, rgba(0, 183, 255, 1) 100%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.data-failure {
  background: rgb(255, 35, 35);
  background: linear-gradient(141deg, rgba(255, 35, 35, 1) 0%, rgba(255, 112, 112, 1) 50%, rgba(255, 95, 39, 1) 100%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media (max-width: 579px) {
  .data-success,
  .data-add,
  .data-failure {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
  }
}
/*=========== end ==========*/ /*=========== Data Test - Form Repeater ==========*/
.form-control-text {
  font-size: 0.875rem;
  color: #555;
  font-weight: 500;
  height: 2.875rem;
  display: flex;
  align-items: center;
  background: #f6f9fc !important;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: auto 10px !important;
}
.-child1-mt-35px:first-child {
  margin-top: 35px;
}
.w-150px {
  width: 150px !important;
}
.popover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #eee !important;
}
.popover .arrow {
  margin: 0 !important;
}
/*=========== end ==========*/

/*=========== Data Test - Form Repeater ==========*/
.form-wizard-vertical > div > ol > li {
  position: relative;
}
.form-wizard-vertical > div > ol > li:first-child::after {
  content: "Step1";
  position: relative;
  top: 0;
  left: -10px;
  font-weight: bold;
}
.form-wizard-vertical > div > ol > li:nth-child(2)::after {
  content: "Step2";
  position: relative;
  top: 0;
  left: -10px;
  font-weight: bold;
}
.form-wizard-vertical > div > ol > li:nth-child(3)::after {
  content: "Step3";
  position: relative;
  top: 0;
  left: -10px;
  font-weight: bold;
}
.form-wizard-vertical > div > ol > li:nth-child(4)::after {
  content: "Step4";
  position: relative;
  top: 0;
  left: -10px;
  font-weight: bold;
}
.form-wizard-vertical > div > ol {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0 0 60px 0;
}
/*  */
.go767851704 span,
.go2478912419 span,
.go2067797667 span {
  display: none !important;
}
.go2067797667,
.go767851704,
.go2478912419 {
  width: 25%;
}
/*  */
.go2067797667:before {
  bottom: -3.99rem;
  float: left;
  left: 50%;
  content: "Ο";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 0;
}
.go2067797667:after {
  color: #93c5ff;
}
.go2067797667 {
  line-height: 4.5rem;
  padding: 0 0.7rem;
  cursor: pointer;
  color: silver;
  border-bottom: 4px solid #cbe3ff !important;
}

.go2067797667:before {
  bottom: -60px !important;
  left: 50% !important;
  content: "▶︎" !important;
  margin-left: -15px;
  color: #93c5ff !important;
  background-color: #cbe3ff !important;
  border: 1px dashed #93c5ff;
  border-radius: 50px !important;
  width: 30px !important;
  line-height: 29px !important;
  height: 30px;
}
@media (max-width: 480px) {
  .form-wizard-vertical > div > ol > span {
    display: none !important;
  }
}
/*  */
.go2478912419 {
  color: #0276f8 !important;
  border-bottom: 3px solid #0276f8 !important;
}
.go2478912419:before {
  bottom: -60px !important;
  left: 50% !important;
  content: "▶︎" !important;
  color: #fff !important;
  margin-left: -15px;
  background-color: #0276f8 !important;
  border-radius: 50px !important;
  width: 30px !important;
  line-height: 30px !important;
  height: 30px;
}
/*  */
.go767851704 {
  color: #0276f8 !important;
  border-bottom: 3px solid #0276f8 !important;
}
.go767851704:before {
  bottom: -60px !important;
  left: 50% !important;
  content: "V" !important;
  color: #fff !important;
  margin-left: -15px;
  font-weight: bold;
  background-color: #0a53a7 !important;
  border-radius: 50px !important;
  width: 30px !important;
  line-height: 30px !important;
  height: 30px;
}
/* Button */
.form-wizard-vertical > div > div > div {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.form-wizard-vertical > div > div > div > button:hover {
  color: #ffffff;
  background-color: #5395ff;
  border-color: #5395ff;
  transition: ease-in-out 0.2s;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -ms-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
}

.form-wizard-vertical > div > div > div > button {
  font-size: 0.875rem;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  padding: 0.875rem 1.5rem;
  border-radius: 0.1875rem;
  background-color: rgba(36, 138, 253, 0.2);
  background-image: none;
  border-color: rgba(36, 138, 253, 0);
  color: #5395ff;
}
/*=========== end ==========*/

body {
  padding-top: 0 !important;
}
