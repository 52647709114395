/**
 * /* You can add global styles to this file, and also import other style files
 *
 * @format
 */

/* === Plugin styles === */

@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~chartist/dist/chartist.min.css";
@import "~nouislider/distribute/nouislider.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
// @import "~@fullcalendar/core/main.css";
// @import "~@fullcalendar/common/main.css";
// @import "~@fullcalendar/daygrid/main.css";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
// @import "~react-bootstrap-typeahead/css/Typeahead-bs4.css";
@import "~react-datepicker/dist/react-datepicker.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "./variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~animate.css/animate.min";
@import "~bootstrap/scss/bootstrap";
@import "./fonts";
@import "./functions";

/* === Icon fonts === */
@import "~ti-icons/css/themify-icons.css";
@import "~@mdi/font/scss/materialdesignicons";
@import "font-awesome/css/font-awesome.min.css";
@import "~simple-line-icons/scss/simple-line-icons";
@import "~typicons.font/src/font/typicons.css";

/* === Template mixins === */
@import "./mixins/animation";
@import "./mixins/badges";
@import "./mixins/buttons";
@import "./mixins/misc";
@import "./mixins/color-functions";
@import "./mixins/cards";
@import "./mixins/blockqoute";
@import "./mixins/popovers";
@import "./mixins/tooltips";
@import "./mixins/no-ui-slider";

/* === Core Styles === */
@import "./background";
@import "./typography";
@import "./reset";
@import "./responsive";
@import "./misc";
@import "./utilities";
@import "./demo";
@import "./spinner";
@import "./dashboard";

/* === Components === */

@import "./components/forms";
@import "./components/checkbox-radio";
@import "./components/icons";
@import "./components/tables";
@import "./components/accordions";
@import "./components/buttons";
@import "./components/breadcrumbs";
@import "./components/badges";
@import "./components/cards";
@import "./components/preview";
@import "./components/tooltips";
@import "./components/user-profile";
@import "./components/popovers";
@import "./components/lists";
@import "./components/bootstrap-progress";
@import "./components/bootstrap-alerts";
@import "./components/tabs";
@import "./components/dropdowns";
@import "./components/pagination";
@import "./components/loaders/loaders";
@import "./components/timeline";
@import "./components/portfolio";
@import "./components/pricing-table";
@import "./components/email/mail-list-container";
@import "./components/email/mail-sidebar";
@import "./components/email/message-content";
@import "./components/todo-list";
@import "./components/spinner";
@import "./components/project-list";
@import "./components/product-tile";
@import "./components/chats";
@import "./components/kanban";
@import "./components/react-table";

@import "./components/landing-screens/auth";

/* === Plugin Overrides === */
@import "./components/plugin-overrides/contex-menu";
@import "./components/plugin-overrides/no-ui-slider";
@import "./components/plugin-overrides/slick-carousel";
@import "./components/plugin-overrides/rating";
@import "./components/plugin-overrides/date-picker";
@import "./components/plugin-overrides/wizard";
@import "./components/plugin-overrides/react-bootstrap-table";
@import "./components/plugin-overrides/sweet-alert";
@import "./components/plugin-overrides/full-calendar";
@import "./components/plugin-overrides/react-photo-gallery";
@import "./components/plugin-overrides/react-tag-autocomplete";
@import "./components/plugin-overrides/jquery-jvectormap";
@import "./components/plugin-overrides/react-table";

/* === Layout === */
@import "./navbar";
@import "./sidebar";
@import "./footer";
@import "./layout";
@import "./settings-panel";

/* ===  + Adobe Design === */
@import "./design/visitor-header.scss";
// @import "./design/visitor-footer.scss";
// @import "./design/visitor-home.scss";
// @import "./design/visitor-our-services.scss";
// @import "./design/visitor-about-aiso.scss";
// @import "./design/visitor-about.scss";
/* === only AISO === */
@import "../styles/aisoCSS/base/aisoCard.css";
@import "../styles/aisoCSS/base/aisoTable.css";
@import "../styles/aisoCSS/base/aisoSearch.css";
@import "../styles/aisoCSS/base/aisoButton.css";
@import "../styles/aisoCSS/base/aisoModal.css";
@import "../styles/aisoCSS/base/aisoColor.css";
@import "../styles/aisoCSS/base/rightBottomBtn.css";
@import "../styles/aisoCSS/base/aisoInput.css";
@import "../styles/aisoCSS/base/aisoImage.css";
@import "../styles/aisoCSS/base/aisoHoverBox.css";

/*** Admin ***/
@import "../styles/aisoCSS/admin/aisoAdmin.css";
@import "../styles/aisoCSS/admin/login.css";
/*** Home ***/
@import "../styles/aisoCSS/home/home.css";
@import "../styles/aisoCSS/home/homeBg.css";
/*** market ***/
@import "../styles/aisoCSS/market.css";
@import "../styles/aisoCSS/aiso.css";
@import "../styles/aisoCSS/sign.css";
@import "../styles/aisoCSS/truncate.css";
@import "../styles/aisoCSS/aisoContentCard.css";
@import "../styles/aisoCSS/aisoTabNavWidth.css";
@import "../styles/aisoCSS/creditcard.scss";
// @import "../styles/aisoCSS/aisoFont.css";

/*** Modal ***/
@import "../styles/aisoCSS/modal.scss";

/*** myWork ***/
// @import "../styles/aisoCSS/myWork/codeAppCreate.css";
@import "../styles/aisoCSS/myWork/dataAnalysis.css";
@import "../styles/aisoCSS/myWork/userSettings.css";
@import "../styles/aisoCSS/myWork/aisoMyWork.css";

/**
 * /* Miscellanoeous
 *
 * @format
 */

body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  background: $content-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  height: 100%;
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;
    .main-panel {
      width: 100%;
      transition: none;
      .content-wrapper {
        padding: 0;
        > div {
          height: 100%;
        }
      }
      .content-wrapper-mywork {
        padding: 0;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function,
    margin $action-transition-duration $action-transition-timing-function;
  // width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh + #{$navbar-height});
  // min-height: 100vh;
  width: calc(100% - 237px);
  position: absolute;
  right: 0;
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}
body {
  // width: 100% !important;
  height: 100vh !important;
  background-image: url("https://cdn.pixabay.com/photo/2015/06/20/07/24/color-815554_1280.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.main-panel-user {
  background-image: url("https://cdn.pixabay.com/photo/2015/06/20/07/24/color-815554_1280.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  transition: width $action-transition-duration $action-transition-timing-function,
    margin $action-transition-duration $action-transition-timing-function;
  // width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  width: 100%;
  position: absolute;
  right: 0;
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  // background: #fafafa;
  // background: #f9fafc;
  padding: 1.68rem 2.5rem;
  width: 100%;
  min-height: 100vh;
  @include flex-grow(1);
  @media (max-width: 640px) {
    padding: 1.68rem 0.5rem;
  }
}

.content-wrapper-mywork {
  max-width: 1920px;
  margin: 0 auto;
  padding: 3rem 2rem 6rem 2rem;
  min-height: calc(100vh - 70px);
  height: 100%;
  @include flex-grow(1);

  @media (max-width: 1540px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 640px) {
    padding: 1.68rem 0;
  }
}
.content-wrapper-user {
  min-height: calc(100vh - 70px);
  height: 100%;
  @include flex-grow(1);
}
.content-wrapper-nocolor {
  padding: 1.68rem 2.5rem;
  width: 100%;
  @include flex-grow(1);
  @media (max-width: 640px) {
    padding: 1.68rem 0.5rem;
  }
}

.container-scroller {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-family: "Roboto", sans-serif;
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}
.page-title {
  color: $black;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: 0.9375rem;
      line-height: 36px;
    }
  }
}
