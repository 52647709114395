/** @format */

.navbar {
  font-weight: 400;
  // background: $navbar-bg;
  backdrop-filter: blur(6px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(6px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-bottom: 4px solid rgba(255, 255, 255, 0.6) !important;
  transition: background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: background $action-transition-duration $action-transition-timing-function;
  -moz-transition: background $action-transition-duration $action-transition-timing-function;
  -ms-transition: background $action-transition-duration $action-transition-timing-function;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.5);

  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    background: transparent;
    width: $sidebar-width-lg;
    height: $navbar-height;
    .navbar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      margin-right: 0;
      padding: 0.25rem 0;

      &.brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 40px);
          max-width: 100%;
          margin: auto;
        }
      }

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        width: calc(#{$sidebar-width-lg} - 130px);
        max-width: 100%;
        height: auto;
        margin: auto;
        vertical-align: middle;
      }
    }
  }
  .navbar-menu-wrapper {
    color: $navbar-menu-color;
    padding-left: 2.55rem;
    padding-right: 2.55rem;
    width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;

    @media (max-width: 991px) {
      width: calc(100% - 55px);
      padding-left: 15px;
      padding-right: 15px;
    }

    .navbar-toggler {
      border: 0;
      color: inherit;
      font-size: 1.25rem;
      border-radius: 0;
      padding: 0;

      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
        transform: rotate(0deg);
        @include transition(transform 0.3s linear);
        .sidebar-icon-only & {
          transform: rotate(90deg);
        }
      }
      &.navbar-toggler-right {
        @media (max-width: 991px) {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }

    .navbar-nav {
      flex-direction: row;
      align-items: center;
      .nav-item {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          height: $navbar-height;
          background-color: inherit;
          @include display-flex;
          @include align-items(center);
        }
        .dropdown {
          .dropdown-toggle {
            border: 0;
            padding: 0;
            background: transparent;
            font-weight: $font-weight-light;
            &:focus {
              box-shadow: none;
            }
            &:after {
              color: theme-color(primary);
              font-size: 1rem;
            }
          }
          .dropdown-menu {
            @extend .dropdownAnimation;
            margin-top: 0;
            font-size: 0.9rem;
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            &.navbar-dropdown {
              .rtl & {
                right: auto;
                left: 0;
              }

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 0.687rem 1.562rem;
                cursor: pointer;

                i {
                  font-size: 17px;
                }

                .ellipsis {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rtl & {
                  i {
                    @extend .mr-0;
                    margin-left: 10px;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
          }
          @media (max-width: 767px) {
            position: static;
            .navbar-dropdown {
              top: $navbar-height;
              width: 100%;
            }
          }

          .count-indicator {
            position: relative;

            i {
              font-size: 1.25rem;
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 55%;
              width: 12px;
              height: 12px;
              border-radius: 100%;
              background: theme-color(warning);
              top: 24px;
              border: 1px solid $white;
            }
            &:after {
              display: none;
            }
          }
        }
        &.nav-search {
          margin-left: 2rem;
          .input-group {
            border-radius: 4px;
            padding: 0.75rem;
            .form-control,
            .input-group-text {
              background: transparent;
              border: 0;
              color: $black;
              padding: 0;
            }
            .input-group-text {
              i {
                font-size: 1.25rem;
                color: $navbar-menu-color;
              }
            }
            .form-control {
              margin-left: 0.7rem;
              @include placeholder {
                color: #6c7383;
              }
            }
          }
        }
        &.nav-profile {
          @extend .d-flex;
          @extend .align-items-center;
          margin-left: 0.2rem;
          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
          }
          &.dropdown {
            .dropdown-toggle {
              &:after {
                display: none;
              }
            }
          }
        }
        &.nav-settings {
          margin: 0;
          i {
            font-size: 1.25rem;
            vertical-align: middle;
          }
        }
      }
      &.navbar-nav-right {
        .nav-item {
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
      &.navbar-nav-left {
        .nav-item {
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }
        @media (min-width: 992px) {
          margin-right: auto;
          .rtl & {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
    }
  }

  .navbar-menu-wrapper-right-icon {
    color: $navbar-menu-color;
    // padding-left: 2.55rem;
    padding-right: 2.55rem;
    width: calc(20%);
    height: $navbar-height;
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;

    @media (max-width: 991px) {
      width: calc(100% - 55px);
      padding-left: 15px;
      padding-right: 15px;
    }

    .navbar-toggler {
      border: 0;
      color: inherit;
      font-size: 1.25rem;
      border-radius: 0;
      padding: 0;

      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
        transform: rotate(0deg);
        @include transition(transform 0.3s linear);
        .sidebar-icon-only & {
          transform: rotate(90deg);
        }
      }
      &.navbar-toggler-right {
        @media (max-width: 991px) {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }

    .navbar-nav {
      flex-direction: row;
      align-items: center;
      .nav-item {
        margin-left: 1rem;
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          height: $navbar-height;
          background-color: inherit;
          @include display-flex;
          @include align-items(center);
        }
        .dropdown {
          .dropdown-toggle {
            border: 0;
            padding: 0;
            background: transparent;
            font-weight: $font-weight-light;
            &:focus {
              box-shadow: none;
            }
            &:after {
              color: theme-color(primary);
              font-size: 1rem;
            }
          }
          .dropdown-menu {
            @extend .dropdownAnimation;
            margin-top: 0;
            font-size: 0.9rem;
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            &.navbar-dropdown {
              .rtl & {
                right: auto;
                left: 0;
              }

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 0.687rem 1.562rem;
                cursor: pointer;

                i {
                  font-size: 17px;
                }

                .ellipsis {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rtl & {
                  i {
                    @extend .mr-0;
                    margin-left: 10px;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
          }
          @media (max-width: 767px) {
            position: static;
            .navbar-dropdown {
              top: $navbar-height;
              width: 100%;
            }
          }

          .count-indicator {
            position: relative;

            i {
              font-size: 1.25rem;
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 55%;
              width: 12px;
              height: 12px;
              border-radius: 100%;
              background: theme-color(warning);
              top: 24px;
              border: 1px solid $white;
            }
            &:after {
              display: none;
            }
          }
        }
        &.nav-search {
          margin-left: 2rem;
          .input-group {
            border-radius: 4px;
            padding: 0.75rem;
            .form-control,
            .input-group-text {
              background: transparent;
              border: 0;
              color: $black;
              padding: 0;
            }
            .input-group-text {
              i {
                font-size: 1.25rem;
                color: $navbar-menu-color;
              }
            }
            .form-control {
              margin-left: 0.7rem;
              @include placeholder {
                color: #6c7383;
              }
            }
          }
        }
        &.nav-profile {
          @extend .d-flex;
          @extend .align-items-center;
          margin-left: 0.2rem;
          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
          }
          &.dropdown {
            .dropdown-toggle {
              &:after {
                display: none;
              }
            }
          }
        }
        &.nav-settings {
          margin: 0;
          i {
            font-size: 1.25rem;
            vertical-align: middle;
          }
        }
      }
      &.navbar-nav-right {
        .nav-item {
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
      &.navbar-nav-left {
        .nav-item {
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }
        @media (min-width: 992px) {
          margin-right: auto;
          .rtl & {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
    }
  }

  .navbar-menu-wrapper-right-icon-mobile {
    color: $navbar-menu-color;
    padding-left: 2.55rem;
    padding-right: 2.55rem;
    width: calc(100%);
    height: $navbar-height;
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;

    @media (max-width: 991px) {
      width: calc(100%);
      padding-left: 15px;
      padding-right: 15px;
    }

    .navbar-toggler {
      border: 0;
      color: inherit;
      font-size: 1.25rem;
      border-radius: 0;
      padding: 0;

      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
        transform: rotate(0deg);
        @include transition(transform 0.3s linear);
        .sidebar-icon-only & {
          transform: rotate(90deg);
        }
      }
      &.navbar-toggler-right {
        @media (max-width: 991px) {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }

    .navbar-nav {
      flex-direction: row;
      align-items: center;
      .nav-item {
        margin-left: 1rem;
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          height: $navbar-height;
          background-color: inherit;
          @include display-flex;
          @include align-items(center);
        }
        .dropdown {
          .dropdown-toggle {
            border: 0;
            padding: 0;
            background: transparent;
            font-weight: $font-weight-light;
            &:focus {
              box-shadow: none;
            }
            &:after {
              color: theme-color(primary);
              font-size: 1rem;
            }
          }
          .dropdown-menu {
            @extend .dropdownAnimation;
            margin-top: 0;
            font-size: 0.9rem;
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            &.navbar-dropdown {
              .rtl & {
                right: auto;
                left: 0;
              }

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 0.687rem 1.562rem;
                cursor: pointer;

                i {
                  font-size: 17px;
                }

                .ellipsis {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rtl & {
                  i {
                    @extend .mr-0;
                    margin-left: 10px;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
          }
          @media (max-width: 767px) {
            position: static;
            .navbar-dropdown {
              top: $navbar-height;
              width: 100%;
            }
          }

          .count-indicator {
            position: relative;

            i {
              font-size: 1.25rem;
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 55%;
              width: 12px;
              height: 12px;
              border-radius: 100%;
              background: theme-color(warning);
              top: 24px;
              border: 1px solid $white;
            }
            &:after {
              display: none;
            }
          }
        }
        &.nav-search {
          margin-left: 2rem;
          .input-group {
            border-radius: 4px;
            padding: 0.75rem;
            .form-control,
            .input-group-text {
              background: transparent;
              border: 0;
              color: $black;
              padding: 0;
            }
            .input-group-text {
              i {
                font-size: 1.25rem;
                color: $navbar-menu-color;
              }
            }
            .form-control {
              margin-left: 0.7rem;
              @include placeholder {
                color: #6c7383;
              }
            }
          }
        }
        &.nav-profile {
          @extend .d-flex;
          @extend .align-items-center;
          margin-left: 0.2rem;
          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
          }
          &.dropdown {
            .dropdown-toggle {
              &:after {
                display: none;
              }
            }
          }
        }
        &.nav-settings {
          margin: 0;
          i {
            font-size: 1.25rem;
            vertical-align: middle;
          }
        }
      }
      &.navbar-nav-right {
        .nav-item {
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
      &.navbar-nav-left {
        .nav-item {
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }
        @media (min-width: 992px) {
          margin-right: auto;
          .rtl & {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .navbar {
    flex-direction: row;
    .navbar-brand-wrapper {
      width: 55px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

// @media (max-width: 480px) {
//   .navbar {
//     .navbar-brand-wrapper {
//       width: 55px;
//       .brand-logo-mini {
//         padding-top: 0px;
//       }
//     }
//   }
// }

/* Navbar color variations */
@each $color, $value in $theme-colors {
  .navbar {
    &.navbar-#{$color} {
      .navbar-menu-wrapper {
        background: $value;
        color: $white;
        .nav-profile-text {
          p {
            @extend .text-white;
          }
        }
        .search-field {
          .input-group {
            input {
              @include input-placeholder {
                color: $white;
              }
            }
            i {
              color: $white;
            }
          }
        }
      }
    }
  }
}
