/** @format */

.custom-app-box {
  /* margin: 0 -100px; */
  margin: 0;
}
.board-wrapper {
  background-color: #f9fafc !important;
  border: 1px solid #ddd !important;
}
.custom-category-box {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  width: 100%;
}
.custom-category {
  width: 100%;
  text-align: center;
  padding: 0.4rem 0.5rem;
  background-color: #f9fafc;
  border: 1px solid #ddd;
  cursor: pointer;
}
.custom-category > a > p {
  color: #888;
  font-size: 13px;
}
.custom-category > a > i {
  color: #888;
  font-size: 16px;
}
.custom-category.active {
  background-color: #fff;
}
.custom-category.active > a > i,
.custom-category.active > a > p {
  color: #5395ff;
}
.custom-category.active > span {
  color: #5395ff;
}

@media (max-width: 1500px) {
  .custom-app-box {
    margin: 0 -50px;
  }
}
@media (max-width: 1300px) {
  .custom-app-box {
    margin: 0 -45px;
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 767px) {
  .custom-app-box {
    margin: 0;
  }
  .custom-category-box {
    flex-flow: row nowrap;
  }
  .custom-category {
    width: calc(100% / 4);
  }
}
@media (max-width: 480px) {
}
