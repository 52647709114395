/** @format */

@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Goldman&family=Righteous&display=swap);
.visitor-header-menu {
  cursor: pointer;
}
.nav-pc-hidden {
  display: none !important;
}
.nav-mo-hidden {
  display: flex !important;
}
.visitor-navbar {
  max-width: 1280px;
  padding: 0 10px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.5);
}
.visitor-navbar-brand-wrapper {
  /* background: #fff; */
  width: 18%;
  height: 70px;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  /* padding-bottom: 0.5em; */
  padding-left: 2.55rem;
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
}
@media (max-width: 1273px) {
  .visitor-navbar-brand-wrapper {
    padding-left: 2rem;
  }
}

.visitor-navbar-menu-wrapper {
  width: calc(100% - 18%);
  height: 70px;
  transition: width 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: width 0.25s ease;
}
.visitor-nabar-nav,
.visitor-right {
  display: flex;
  flex-flow: row wrap !important;
  align-items: center;
}
.visitor-right {
  margin-right: 2rem;
}
.visitor-right > .nav-item {
  margin: 0 1rem;
}
.navbar .visitor-navbar-brand-wrapper .navbar-brand img {
  width: calc(237px - 130px);
  max-width: 100%;
  height: auto;
  margin: auto;
  vertical-align: middle;
  cursor: pointer;
}
.login-bg {
  background-color: #f2f8ff;
  width: 100%;
  height: 100%;
}
.login-bg-color {
  background-color: #f2f8ff;
}
.sign-font {
  font-family: "Righteous", cursive, Helvetica, Arial, serif;
  color: #5395ff;
  letter-spacing: 20px;
  font-size: auto;
}

@media (max-width: 992px) {
  .nav-pc-hidden {
    display: flex !important;
  }
  .nav-mo-hidden {
    display: none !important;
  }
  .visitor-navbar,
  .visitor-navbar-brand-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0.5em;
    padding-right: 0;
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
  }

  .visitor-navbar-brand-wrapper .navbar-brand {
    margin: 0 !important;
  }
  .visitor-header-menu div {
    width: 100% !important;
    padding: 1.4rem 0 !important;
    font-family: "GmarketSansMedium";
  }
  .mo-brand-logo {
    width: calc(100% - 50px);
  }
  .mo-brand-logo img {
    margin-left: -50px;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .sign-font {
    letter-spacing: 10px;
    font-size: 40px;
  }
}
@media (max-width: 661px) {
  .visitor-navbar-brand-wrapper {
    display: flex;
  }
}
@media (max-width: 640px) {
  .visitor-nabar-nav {
    width: 100% !important;
    overflow-x: auto !important;
    overflow-y: hidden;
  }
  .visitor-header-menu div {
    width: 100% !important;
    padding: 1.4rem 0 !important;
  }
  .visitor-right {
    display: none !important;
  }
}
