/** @format */
a:hover {
  font-weight: bold !important;
  transition: ease-in-out 0.1s;
}
.btn {
  font-family: "GmarketSansMedium" !important;
}
.btn-bold {
  font-weight: bold !important;
}
.rounded-none-btn {
  border-radius: 0 !important;
}

/*=====================================================*/
.glass_in_outline_btn {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: 2px solid rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 30px !important;
}
.glass_in_outline_btn:hover {
  background-color: rgba(255, 255, 255, 0.4) !important;
  font-weight: bold;
  transition: ease-in-out 0.1s;
}
.glass_in_outline_btn:focus,
.glass_in_outline_btn:active,
.glass_in_outline_btn.active {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

/*=====================================================*/
.glass_in_btn {
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4) !important;
  border-radius: 30px !important;
}
.glass_in_btn:hover {
  background-color: rgba(255, 255, 255, 0.6) !important;
  font-weight: bold;
  transition: ease-in-out 0.1s;
}
.glass_in_btn:focus,
.glass_in_btn:active,
.glass_in_btn.active {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

/*=====================================================*/
.glass_out_btn {
  backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3) !important;
  border-radius: 30px !important;
}
.glass_out_btn:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
  font-weight: bold;
  transition: ease-in-out 0.1s;
}
.glass_out_btn:focus,
.glass_out_btn:active,
.glass_out_btn.active {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

/*=====================================================*/
.glass_in_box_btn {
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4) !important;
  border-radius: 3px !important;
  color: #000 !important;
}
.glass_in_box_btn:hover {
  background-color: rgba(255, 255, 255, 0.6) !important;
  font-weight: bold !important;
  transition: ease-in-out 0.1s !important;
}
.glass_in_box_btn:focus,
.glass_in_box_btn:active,
.glass_in_box_btn.active {
  background-color: rgba(255, 255, 255, 0.8) !important;
  transition: ease-in-out 0.2s;
}

/*=====================================================*/
.glass_out_box_btn {
  backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4) !important;
  border: 2px solid rgba(255, 255, 255, 0.4) !important;
  color: #000 !important;
}
.glass_out_box_btn:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
  font-weight: bold !important;
  transition: ease-in-out 0.1s !important;
}
.glass_out_box_btn:focus,
.glass_out_box_btn:active,
.glass_out_box_btn.active {
  background-color: rgba(255, 255, 255, 1) !important;
  transition: ease-in-out 0.2s;
}

/*=====================================================*/
.aiso-button-basic {
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
}
.aiso-button-basic:hover,
.aiso-button-basic:active,
.aiso-button-basic.active {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
  transition: ease-in-out 0.2s;
}
/*=====================================================*/
.aiso-button-in {
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 1) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
}
.aiso-button-in:hover,
.aiso-button-in:active,
.aiso-button-in.active {
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(000, 102, 255, 0.2) !important;
  transition: ease-in-out 0.1s;
}
/*=====================================================*/
.btn-danger {
  background-color: #f96868 !important;
  border-color: #f96868 !important;
}
.btn-inverse-danger {
  color: #615a5a !important;
}
.btn-inverse-danger:hover,
.btn-inverse-danger:active,
.btn-inverse-danger.active {
  background-color: #f96868 !important;
  border-color: #f96868 !important;
  color: #fff !important;
}
.btn-outline-danger {
  color: #f96868 !important;
  border-color: #f96868 !important;
}
.btn-outline-danger:hover,
.btn-outline-danger:active,
.btn-outline-danger.active {
  color: #fff !important;
  background-color: #f96868 !important;
  border-color: #f96868 !important;
}
/*=====================================================*/
/* BG:Gray -> BG:Blue */
.btn-outline-secondaryBlue {
  color: #aab2bd !important;
  border-color: #aab2bd !important;
}
.btn-outline-secondaryBlue:hover,
.btn-outline-secondaryBlue:active,
.btn-outline-secondaryBlue.active {
  color: #fff !important;
  background-color: #5395ff !important;
  border-color: #5395ff !important;
}

/* BG:Gray -> BG:Blue */
.btn-outline-blue {
  color: #555 !important;
  /* color: #aab2bd !important; */
  border-color: #aab2bd !important;
}
.btn-outline-blue:hover,
.btn-outline-blue:active,
.btn-outline-blue.active {
  color: #fff !important;
  background-color: #5395ff !important;
  border-color: #5395ff !important;
}
/*=====================================================*/
/* BG:White -> BG:Blue */
.btn-whitePrimary {
  color: #555 !important;
  background-color: #fff !important;
  border: none !important;
}
.btn-whitePrimary:hover {
  color: #fff !important;
  background-color: #5395ff !important;
}
.btn-whitePrimary:focus,
.btn-whitePrimary:active,
.btn-whitePrimary.active {
  color: #fff !important;
  background-color: #5395ff !important;
  transition: all 0.1s;
}
/* BG:White -> BG:Danger */
.btn-whiteDanger {
  color: #555 !important;
  background-color: #fff !important;
  border: none !important;
}
.btn-whiteDanger:hover {
  color: #fff !important;
  background-color: #f9686879 !important;
}
.btn-whiteDanger:focus,
.btn-whiteDanger:active,
.btn-whiteDanger.active {
  background-color: #f96868 !important;
  color: #fff !important;
  transition: all 0.3s;
}

/* Text:Gray -> Text:Blue */
.btn-text-grayPrimary {
  color: #aaa !important;
  background-color: #f6f9fc !important;
  border: none !important;
}
.btn-text-grayPrimary:hover {
  color: #5395ff !important;
}

/* BG:Gray -> BG:Blue */
.btn-grayPrimary {
  color: #555 !important;
  background-color: #f6f9fc !important;
  border: none !important;
}
.btn-grayPrimary:hover {
  color: #fff !important;
  background-color: #5395ff !important;
}

/* BG:dark -> BG:Blue */
.btn-darkPrimary {
  color: #fff !important;
  background-color: #ccc !important;
  border: none !important;
}
.btn-darkPrimary:hover {
  color: #fff !important;
  background-color: #5395ff !important;
}
.btn-outline-grayPrimary {
  color: #555 !important;
  border: 1px solid #bbb !important;
}
.btn-outline-grayPrimary:hover {
  color: #fff !important;
  background-color: #5395ff !important;
  border: 1px solid #5395ff !important;
}
.btn-outline-grayPrimary:focus,
.btn-outline-grayPrimary:active,
.btn-outline-grayPrimary.active {
  color: #fff !important;
  background-color: #0869d8 !important;
  border: 1px solid #0869d8 !important;
}
/*=====================================================*/
/* BG:Gray -> BG:Red */
.btn-grayDanger {
  color: #555 !important;
  background-color: #f6f9fc !important;
  border: none !important;
}
.btn-grayDanger:hover {
  color: #fff !important;
  background-color: #f96868 !important;
}
.btn-outline-grayDanger {
  color: #555 !important;
  border: 1px solid #bbb !important;
}
.btn-outline-grayDanger:hover {
  color: #fff !important;
  background-color: #f96868 !important;
  border: 1px solid #f96868 !important;
}
.btn-outline-grayDanger:focus,
.btn-outline-grayDanger:active,
.btn-outline-grayDanger.active {
  color: #fff !important;
  background-color: #d65151 !important;
  border: 1px solid #d65151 !important;
}
/*=====================================================*/
/* opacity Button */
.btn-inverse-white {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  background-color: rgba(255, 255, 255, 0.205) !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.075) !important;
}
.btn-inverse-white:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.23) !important;
  transition: all 0.3s;
}
.btn-inverse-white:focus,
.btn-inverse-white:active,
.btn-inverse-white.active {
  background-color: rgba(0, 0, 0, 0.411) !important;
  transition: all 0.3s;
}
/*=====================================================*/
/* opacity Button */
.btn-inverse-black {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  background-color: rgba(0, 0, 0, 0.568) !important;
}
.btn-inverse-black:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.23) !important;
  transition: all 0.3s;
}
.btn-inverse-black:focus,
.btn-inverse-black:active,
.btn-inverse-black.active {
  background-color: rgba(0, 0, 0, 0.671) !important;
  transition: all 0.3s;
}
/*=====================================================*/
/* lightGray Button */
.text-xs {
  font-size: 12px !important;
}
.btn-lightGray {
  color: #888 !important;
  background-color: #f6f9fc !important;
  /* border-radius: 9999px !important; */
}
.btn-lightGray:hover {
  color: #5395ff !important;
  background-color: #eee !important;
  transition: all 0.3s;
}
.btn-lightGray:focus,
.btn-lightGray:active,
.btn-lightGray.active {
  transition: all 0.3s;
  box-shadow: 0 0 3px 3px #858d97;
}
/*=====================================================*/
/* Inverse-Gray Button */
.btn-inverse-gray {
  color: #555 !important;
  background-color: #f8f9fa !important;
}
.btn-inverse-gray:hover {
  color: #fff !important;
  background-color: #858d97 !important;
  transition: all 0.3s;
}
.btn-inverse-gray:focus,
.btn-inverse-gray:active,
.btn-inverse-gray.active {
  transition: all 0.3s;
  border: 3px solid #eee;
}
/*=====================================================*/
/* Gray Button */
.btn-gray {
  color: #555 !important;
  background-color: #f6f9fc !important;
}
.btn-gray:hover {
  color: #fff !important;
  background-color: #888 !important;
  transition: all 0.3s;
}
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active {
  box-shadow: 0 0 0 3px rgba(134, 134, 134, 0.555);
  background-color: #888 !important;
}
/*=====================================================*/
/* Outline Gray Button */
.btn-outline-gray {
  color: #555 !important;
  border: 1px solid #ccc !important;
}
.btn-outline-gray:hover {
  color: #fff !important;
  background-color: #aaa !important;
  transition: all 0.3s;
}
.btn-outline-gray:focus,
.btn-outline-gray:active,
.btn-outline-gray.active {
  box-shadow: 0 0 0 3px rgba(134, 134, 134, 0.555);
  background-color: #888 !important;
}
/*=====================================================*/
/* White Button */
.btn-white {
  color: #555 !important;
  background-color: #fff !important;
}
.btn-white:hover {
  color: #fff !important;
  background-color: #5395ff !important;
  transition: all 0.3s;
}
.btn-white:focus,
.btn-white:active,
.btn-white.active {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.555);
  background-color: #5395ff !important;
  color: #fff !important;
}
/*=====================================================*/
/* White Button */
.btn-white2 {
  color: #888 !important;
  background-color: #fff !important;
}
.btn-white2:hover {
  color: #5395ff !important;
  transition: all 0.3s;
}
.btn-white2:focus,
.btn-white2:active,
.btn-white2.active {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.555);
  color: #5395ff !important;
}
/*=====================================================*/
/* btn-white3 */
.btn-white3 {
  color: #888 !important;
  background-color: #fff !important;
}
.btn-white3:hover {
  color: #5395ff !important;
  transition: all 0.3s;
}
.btn-white3.active {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.555);
  color: #5395ff !important;
}
/*=====================================================*/
/* White Button */
.btn-text-blue {
  color: #5395ff !important;
  background-color: #fff !important;
}
.btn-text-blue:hover {
  color: #0869d8 !important;
  transition: all 0.3s;
}
.btn-text-blue:focus,
.btn-text-blue:active,
.btn-text-blue.active {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.555);
  color: #0869d8 !important;
}
/*=====================================================*/
/* Search Button */
.btn-search-area {
  display: flex !important;
  justify-content: center;
  align-items: flex-end !important;
}
.btn-search {
  color: #5395ff !important;
  background-color: #f1f8ff !important;
}
.btn-search:hover {
  color: #333 !important;
  background-color: #68afff !important;
  transition: all 0.3s;
}
.btn-search:focus,
.btn-search:active,
.btn-search.active {
  background-color: #5395ff !important;
  color: #333 !important;
}

/*=====================================================*/
/* Clear Button */
.btn-clear-area {
  display: flex !important;
  justify-content: center;
}
.btn-clear {
  color: #5395ff !important;
  background-color: #f6f9fc !important;
}
.btn-clear:hover {
  color: #fff !important;
  background-color: #5395ff !important;
  transition: all 0.3s;
}
.btn-clear:hover > span > svg {
  color: #fff !important;
}
.btn-clear:active,
.btn-clear.active {
  color: #fff !important;
  background-color: #5395ff !important;
}

/*=====================================================*/
/* 테이블 드롭다운 메뉴 */
.dropdown .no-arrow.dropdown-toggle:after {
  content: none;
}
/*=====================================================*/
/*========== Radio Button ==========*/
/* Gray Radio Button */
.form-check-gray .form-check-label input[type="radio"] + .input-helper:before {
  border: 2px solid #aaa !important;
}
.form-check-gray.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #aaa;
}
/*=====================================================*/
/*========== badge Style ==========*/
.aiso-badge {
  padding: 2px 4px !important;
  font-weight: 900 !important;
  font-size: 11px !important;
  border-radius: 9999px !important;
}
/* Gray badge */
.badge-gray {
  border: 1px solid #aaa;
  color: #ffffff;
  background-color: #aaa;
}
/* LightGray Badge */
.badge-lightGray {
  border: 1px solid #eee;
  color: #555;
  background-color: #eee;
}
/* White Badge */
.badge-white {
  border: 1px solid #fff;
  color: #5395ff;
  background-color: #fff;
}
/* WhitePrimary Badge */
.badge-whiteBlue {
  color: #888;
  background-color: #fff;
  font-weight: bold !important;
  text-align: left !important;
}
/* Danger Badge */
.badge-danger {
  background-color: #f96868 !important;
  border-color: #f96868 !important;
}

/*=====================================================*/
/*========== 기본 탭 메뉴 스타일 ==========*/
/* Base */
.tab-item-base {
  text-align: center;
  font-weight: 600;
  padding: 1rem 1.5rem;
  font-size: 14px;
  background-color: #f0f1f5;
  border: 1px solid #ddd;
}
.tab-item-base-click {
  background-color: #fff;
  color: #5395ff;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-top: 2px solid #434a54;
}
/*=====================================================*/
/*===== File-BTN =====*/
.custom-file-input {
  position: absolute !important;
  height: 100% !important;
  top: 0;
  left: 0;
}
/*=====================================================*/
/*===== Admin Top BTN =====*/
.btn-admin-top {
  padding: 0.8rem 1.2rem !important;
  background-color: #fff !important;
  border-radius: 0 !important;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 1rem;
}
.btn-admin-top span {
  color: #888;
  font-weight: bold;
  position: relative;
  z-index: 1;
  transition: color 0.6s cubic-bezier(0.53, 0.21, 0, 1);
}
.btn-admin-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 !important;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
}
.btn-admin-top:hover span {
  font-weight: bold;
  color: #fff;
}
.btn-admin-top:hover::before {
  transform-origin: 0 0;
  transform: scaleX(1);
}
.btn-admin-top:focus,
.btn-admin-top:active,
.btn-admin-top.active {
  background-color: #5395ff !important;
}
.btn-admin-top:focus span,
.btn-admin-top:active span,
.btn-admin-top.active span {
  color: #fff;
}

/*===== Admin Top BTN - Primary =====*/
.btn-admin-top.admin-primary::before {
  background-color: #5395ff;
}
.btn-admin-top.admin-primary:focus,
.btn-admin-top.admin-primary:active,
.btn-admin-top.admin-primary.active {
  background-color: #5395ff !important;
}
/*===== Admin Top BTN - danger =====*/
.btn-admin-top.admin-danger::before {
  background-color: #f96868;
}
.btn-admin-top.admin-danger:focus,
.btn-admin-top.admin-danger:active,
.btn-admin-top.admin-danger.active {
  background-color: #f96868 !important;
}
/*===== Admin Top BTN - danger =====*/
.btn-admin-top.admin-gray::before {
  background-color: #888;
}
.btn-admin-top.admin-gray:focus,
.btn-admin-top.admin-gray:active,
.btn-admin-top.admin-gray.active {
  background-color: #888 !important;
}

@media (max-width: 480px) {
  .btn-admin-top span {
    line-height: 1.5;
  }
  .btn-admin-top span > i {
    display: none;
  }
}
